// variables and mixins
@import "chaintier/variables";
@import "chaintier/mixins";
@import "chaintier/shadows";

// plugin css
@import "chaintier/plugins/plugin-nouislider";
@import "chaintier/plugins/plugin-perfect-scrollbar";
@import "chaintier/plugins/plugin-react-datetime";
@import "chaintier/plugins/plugin-react-bootstrap-sweetalert";
@import "chaintier/plugins/plugin-react-chartist";
@import "chaintier/plugins/plugin-react-big-calendar";
@import "chaintier/plugins/plugin-react-jvectormap";
@import "chaintier/plugins/plugin-react-table";
@import "chaintier/plugins/plugin-react-tagsinput";

// Core CSS
@import "chaintier/misc";
@import "chaintier/fileupload";
@import "chaintier/fixed-plugin";

// @import 'common/all';
@import 'common/loading';
@import 'common/material';
@import 'common/intl';
@import 'common/perfect-scrollbar';

@import 'common/images-upload.scss';
@import './exam';
